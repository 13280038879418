<template>
  <Layout>
    <PageHeader :title="'New Event'" />
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-sm-3">
            <div class="card">
              <div class="card-body">
                <div class="met-profile">
                  <div class="met-profile-main">
                    <div class="met-profile-main-pic">
                      <file-upload
                        ref="upload"
                        class="file profile-img bordered"
                        v-model="image"
                        accept="image/png, image/gif, image/jpeg"
                        extensions="gif,jpg,jpeg,png"
                      >
                        <img
                          :src="
                            newEvent.profileImg
                              ? $apiDomain + newEvent.profileImg
                              : require('@/assets/images/no-image.jpg')
                          "
                          alt="Event Image"
                        />
                        <span class="fro-profile_main-pic-change">
                          <i class="fas fa-camera"></i>
                        </span>
                      </file-upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-9">
            <div class="card">
              <div class="card-body">
                <div class="form-group mt-2">
                  <label>Event Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Event Name"
                    autocomplete="nope"
                    v-model="newEvent.name"
                    @blur="$v.newEvent.name.$touch()"
                    :class="{
                      'is-invalid': $v.newEvent.name.$error,
                    }"
                  />
                  <div class="invalid-feedback">Required</div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <label for="start-date">Date</label>
                    <date-picker
                      v-model="newEvent.date"
                      type="datetime"
                      format="YYYY-MM-DD hh:mm a"
                      placeholder="Select datetime"
                    ></date-picker>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label>Description</label>

                  <ckeditor
                    v-model="newEvent.desc"
                    :editor="editor"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
                <button
                  class="btn btn-primary floated-button pr-3 pl-3"
                  @click="updateEvent"
                >
                  Save
                </button>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
/*eslint-disable*/
import { HTTP } from "@/main-source";
import router from "@/router";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { FormWizard, TabContent } from "vue-form-wizard";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "NewInvestor",
  data() {
    return {
      passInputType: "password",
      firms: [],
      investors: [],
      image: [],
      newEvent: {
        name: null,
        date: null,
        profileImg: null,
        selectedInvestors: null,
        desc: null,
      },
      selectedInvestorsCount: 0,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",

            "|",
            "undo",
            "redo",
          ],
        },
        image: {
          toolbar: [
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        language: "en",
      },
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Fon",
          field: "fon",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
      ],
    };
  },
  validations: {
    newEvent: {
      name: { required },
      date: { required },
    },
  },
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    ckeditor: CKEditor.component,
  },
  created() {
    this.getEventDetail();
  },
  methods: {
    getEventDetail: function () {
      HTTP.get("events/" + this.$route.params.id).then((result) => {
        this.newEvent = result.data.rows[0];
        this.newEvent.date = new Date(this.newEvent.date);
        this.xhrRequest = false;
      });
    },

    setSelectedCount: function () {
      this.selectedInvestorsCount = this.$refs["investorLists"]
        ? this.$refs["investorLists"].selectedRows.length
        : 0;
    },
    updateEvent: function () {
      HTTP.put("events/" + this.$route.params.id, this.newEvent)
        .then(() => {
          router.push({
            name: "event-detail",
            params: {
              id: this.$route.params.id,
            },
          });
        })
        .catch((error) => {
          console.log("🚀 ~ file: new.vue ~ line 312 ~ error", error);
        });
    },
  },

  watch: {
    image: function () {
      let fd = new FormData();

      fd.append("files", this.image[0].file, this.image[0].name);

      HTTP.post("/files/", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          this.newEvent.profileImg = res.data[0].path;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
